import React from 'react';
import colors from '../utils/colors';
import flameLogo from '../resources/conf/flame.png';
import { Image } from 'semantic-ui-react';

export function RelightLogo(props) {
	return (
		<div>
			<div style={{ display: 'flex', justifyContent: 'center', }}>
				<Image src={flameLogo} style={{ marginTop: 20, maxWidth: '70%', borderRadius: 20 }} />
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', fontSize: 14, color: colors.darkGray, fontStyle: 'italic' }}>
				Relight Conference 2024
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', fontSize: 36, fontWeight: 'bold', color: colors.black, marginTop: 0 }}>
				SALVATION
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', fontSize: 16, fontWeight: 'bold', color: colors.darkGray }}>
				May 10-11
			</div>
		</div>
	)
}
import '../App.css';
import React from 'react';
import withRouter from '../utils/withRouter';
import Page from '../components/Page';
import { Accordion, Button, Grid, Icon, Image, Segment } from 'semantic-ui-react';

import colors from '../utils/colors';
import ReactGA from 'react-ga4';
import ActiveText from '../responsive/ActiveText';
import flameLogo from '../resources/conf/flame.png';
import { RelightLogo } from '../components/RelightLogo';

class EventPortal extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      error: null,
      loading: true,
      activeIndex: -1,
      speakers: [
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2FlofO73eP9gpv2ZKHi2je_1714926333810_large.png',
          name: 'Dr. Alfred Poirier',
          role: 'Main Speaker',
          title: 'Professor at Westminster Theological Seminary',
          location: 'Philadelphia, Pennsylvania',
          description: "Dr. Alfred Poirier is professor of pastoral theology at Westminster Theological Seminary. He has held several additional teaching positions and has 38 years of pastoral ministry experience, including his time as a pastor at Rocky Mountain Community Church (PCA) in Billings, Montana for 26 years. Dr. Poirier’s academic interests lie in the areas of pastoral counseling and expository preaching. He is the author of The Peacemaking Pastor (Baker, 2006), and Words that Cut: Learning to Take Criticism in Light of the Gospel (Peacemaker Ministries) "
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F9IGy1DrtG2ajsi6vcn2P_1670350273255_512.png',
          name: 'Yaroslav Narovchenko',
          role: 'Seminar Speaker',
          title: 'Senior Pastor at Lifeway Baptist Church',
          location: 'Philadelphia, Pennsylvania',
          description: ""
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2FmPmXOAUZhlaeyg8OrtA6_1714926846043_large.png',
          name: 'David Letushko',
          role: 'Seminar Speaker',
          title: "Pastor at The Gospel Church",
          location: 'Richland, NJ',
          description: "David has been married to Nancy for almost 12 years and has 3 children. He is a graduate from Southeastern Baptist Theological Seminary and one of the pastors at the Gospel Church in NJ. David loves to spend time out on the ocean with his family, surfing and fishing. "
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F1WPkNWVFgMYDix70zcrn_1714927179658_large.png',
          name: 'Artem Kharmyshev',
          role: 'Seminar Speaker',
          title: "Christian, Photographer",
          location: 'Philadelphia, PA',
          description: "Artem is the co-founder of the \"Pilgrim Republic\" (Foundation that served orphans). Was born and raised in Mariupol, Ukraine. "
        },
        {
          img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2Fw8qBcBH9r6T2V2RBScdS_1715357357483_large.png',
          name: 'Anthony Butler',
          role: 'Seminar Speaker',
          title: "Member of Olney Baptist Church",
          location: 'Philadelphia, PA',
          description: "Anthony is a seminary student at Westminster Theological Seminary. He regularly teaches the Sunday School class on Sunday mornings at Olney Baptist Church."
        },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2FtVBttvdCY1BQEXbyWxBN_1689643690295_small.png',
        //   name: 'Tetyana Danylova',
        //   role: 'Seminar',
        //   title: "Serves at Lifeway Baptist Church",
        //   location: 'Philadelphia, Pennsylvania',
        //   description: "Teyana started serving as a Sunday school teacher and later became the S.S. director in her church. In 2003, she graduated from the Children’s Teaching Ministry Leadership Institute in Moscow and soon after graduated from the Irpin Biblical Seminary in Kiev in 2005. She also graduated from the Christian Humanitarian and Economic Open University in Odessa in 2022. Tetyana organizes and holds outreaches for kids, \"Good News clubs\" (GNC®), residential, and day camps."
        // },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F1dTfPwxclTfmhvAOCHqV_1671294207874_512.png',
        //   name: 'Benjamin Mishin',
        //   role: 'Speaker',
        //   title: 'Pastor at Lifeway Baptist Church',
        //   location: 'Philadelphia, Pennsylvania',
        //   description: "Benjamin came to the United States in 1995 from Moldova and planted Lifeway Baptist Church in 2001. He holds a Master of Divinity degree from Westminster Theological Seminary. He and his wife Nadia have been married for 26 years, and they have two children who are now married as well. He enjoys preaching and leading the church in worship."
        // },
        // {
        //   img: 'https://storage.googleapis.com/lifeway-scheduler.appspot.com/avatars%2Fall%2F2WQpmTdqPYlwP1cOpDBw_1670346309720_512.png',
        //   name: 'Eugene Yakovlev',
        //   role: 'Seminar',
        //   title: "Young Adults Pastor at Lifeway Baptist Church",
        //   location: 'Philadelphia, Pennsylvania',
        //   description: "Eugene is the Young Adults Pastor and ministers to young professionals at LBC (ages 18-30). He is passionate about discipling young men and helping them grow in the knowledge and love of Jesus Christ. In 2021, he graduated from Drexel University with a Computer Science degree and currently works as a Software Engineer at a proprietary trading firm. Outside of work, Eugene loves to explore the integration point between technology and ministry. He has created Scheduly, church scheduling software that is used by many churches."
        // },
      ],
      contacts: [
        {
          role: 'Organizers',
          members: [
            {
              name: 'Yaroslav Narovchenko',
              phone: '215-941-9639'
            },
            {
              name: 'Eugene Yakovlev',
              phone: '267-210-4743'
            },
          ]
        },
        {
          role: 'Registration',
          members: [
            {
              name: 'Sveta Zozulya',
              phone: '717-991-1678'
            },
            {
              name: 'Mike Bersim',
              phone: '609-664-8494'
            }
          ]
        },
        {
          role: 'Accommodations',
          members: [
            {
              name: 'Luba Dimov',
              phone: '215-907-0287'
            }
          ]
        },
        {
          role: 'Security',
          members: [
            {
              name: 'Alexey Otych',
              phone: '267-528-5268'
            }
          ]
        },
        {
          role: 'Pastors',
          members: [
            {
              name: 'Yaroslav Narovchenko',
              phone: '215-941-9639'
            },
            {
              name: 'Benjamin Mishin',
              phone: '215-990-2648'
            }
          ]
        },
      ]
    }

    this.timer = undefined;
  }

  componentDidMount = async () => 
  {
    ReactGA.send('pageview');

    this.setState({ loading: false });
  }

  handleClick = (index) => {
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex });
  }

  render() 
  {
    const {
      loading,
      error,
      speakers,
      contacts,
      activeIndex,
    } = this.state;

		const {
			btnLinks,
		} = this.props;

    return (
      <Page 
        helmet={`Relight 2024`}
        loading={loading}
        error={error}
        hideHeader
        backgroundColor={colors.pageBackground}
        windowWidth={this.props.windowWidth}
      > 
       <div style={{display: 'flex', justifyContent: 'center', lineHeight: 1.6}}>
      	<Segment raised style={{maxWidth: 550, padding: 20, backgroundColor: colors.white, borderRadius: 30, borderColor: colors.lightGray, borderWidth: 1, borderStyle: 'solid'}}>
          
          {/* Welcome Section */}
            {/* <div style={{ display: 'flex', justifyContent: 'center',  }}>
              <Image src={LOGO} style={{ marginTop: 0, maxWidth: '100%', borderRadius: 20 }}/>
          </div> */}
            <RelightLogo />
            <div style={{ textAlign: 'left', marginTop: 40, marginBottom: 40 }}>
            {/* <i>"Go therefore and <b>make disciples</b> .."</i> */}
            {/* <ActiveText
              textOptions={[
                "Welcome to Relight Conference!",
                "We're glad you are here!",
                "See schedules below",
                "Try out our delicious coffee downstairs",
                "Introduce yourself to someone new!",
                "If you have any questions, ask our Staff!",
                "Thank you for being part of Relight!",
                "We hope to see you again soon!",
                ]}
              fontSize={20}
              fontWeight={'normal'}
              fontColor={colors.black}
              intervalMs={25}
              sleepMs={2250}
              restartLoop={true}
            /> */}
          </div>

          {/* Schedule Section */}
          <div style={{ marginTop: 30, marginBottom: 50 }}>
            <PageTitle text='SCHEDULE' />
          </div>

          <div style={{ margin: 10, marginTop: 0 }}>
            {btnLinks?.map((btn, idx) => 
            {
              return (
                <Button 
                  fluid 
                  style={{ marginTop: idx > 0 ? 15 : 0 }} 
                  color={btn.color}
                  onClick={() => {
                    if (btn.internalUrl) {
                      this.props.router.navigate(btn.internalUrl)
                    } else if (btn.externalUrl) {
                      window.location.assign(btn.externalUrl)
                    } else {
                      throw new Error('URL not defined');
                    }
                  }}
                >
                  {btn.label}
                </Button>
              )
            })}
          </div>

          {/* Info Section */}
          <div style={{marginTop: 60, marginBottom: 20}}>
            <PageTitle text='ABOUT US' />
          </div>

					<div style={{color: colors.black, width: '100%', textAlign: 'left', padding: 20, paddingLeft: 10, paddingRight: 10, whiteSpace: 'pre-wrap'}}>
              <ActiveText
                textOptions={[
                  "Relight conference is an annual youth conference that was started in 2011. Our goal is to call this generation to make their lives count by making Jesus' name known. We emphasize the gospel and the ministry of the local church in the young believer's life. Whether you have been a follower of Christ for 10 years or 10 days, you have come to the right place to nourish your soul with the Word of God, worship the King of Kings, fellowship with like-minded believers, and to stir up your affections for Christ. ",
                ]}
                fontColor={colors.darkGray}
                fontSize={16}
                intervalMs={0}
              />
          </div>

          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <PageTitle text='SPEAKERS' />
          </div>
          {/* Speakers Section */}
          {speakers?.map((speaker, idx) => {
            return (
              <div style={{textAlign: 'center', marginBottom: speaker.description ? 30 : 40}}>
                 <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Image src={speaker.img} circular style={{maxWidth: 140}}/>
                 </div>
                <p style={{marginTop: 12, fontSize: 18}}>
                  <b>{speaker.name} </b> · <i>{speaker.role}</i>
                  <p style={{ color: colors.black, fontSize: 14, marginTop: 3 }}>
                    <b>{speaker.title}</b>
                  </p>
                  <p style={{color: colors.darkGray, fontSize: 14, marginTop: -10}}>
                    <p>{speaker.location}</p>
                  </p>
                </p>
                {speaker.description && 
                  <div style={{color: colors.black, padding: 10, marginBottom: 10, paddingTop: 0, whiteSpace: 'pre-wrap'}}>
                    {speaker.description}
                  </div>
                }
              </div>
            )
          })}

          {/* Contact Section */}
          <div style={{marginBottom: 50}}>
            <div style={{ marginTop: 30, marginBottom: 0 }}>
              <PageTitle text='CONTACT US' />
            </div>
            <div style={{color: colors.darkGray, width: '100%', textAlign: 'center', marginBottom: 0, padding: 20}}>
              If you have any questions, please reach out to us.
            </div>
            <Accordion fluid styled style={{ marginTop: 0 }}>
              {contacts.map((contact, idx) => {
                return (
                  <>
                    <Accordion.Title
                      active={activeIndex === idx}
                      index={idx}
                      onClick={() => this.handleClick(idx)}
                    >
                      <Icon name='dropdown' />
                      {contact?.role}
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === idx}>
                      <Grid fluid stackable>
                        {contact?.members?.map((member, idx) => {
                          return (
                            <Grid.Row>
                              <Grid.Column width={8}>
                                <b>{member.name}</b> 
                              </Grid.Column>
                              <Grid.Column width={8}>
                                {member.phone}
                              </Grid.Column>
                            </Grid.Row>)
                        })}
                      </Grid>
                    </Accordion.Content>
                  </>
                )
              })}
              
              </Accordion>
              <div style={{ color: colors.darkGray, width: '100%', textAlign: 'center', marginBottom: 10, padding: 20 }}>
                Follow us online for future events and conferences.
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <Button
                  circular
                  size='massive'
                  icon='instagram'
                  onClick={() => window.location.assign('https://www.instagram.com/relight_conference/')}
                  color='instagram'
                  style={{ marginRight: 10 }}
                />
                <Button
                  circular
                  size='massive'
                  icon='facebook'
                  onClick={() => window.location.assign('https://www.facebook.com/RelightConference')}
                  color='facebook'
                />
              </div>

          </div>
        </Segment>
        </div>
        <div style={{marginTop: 30, textAlign: 'center', color: colors.midGray}}>
          Powered by <a href='https://scheduly.org/'>Scheduly</a>
        </div>
      </Page>
    );
  }
}


const PageTitle = (props) =>
{
  const { text } = props;
  return (
    <div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold', backgroundColor: colors.black, color: colors.white }}>
      {text}
    </div>
  )
}
export default withRouter(EventPortal);
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import { Sidebar, Segment, Menu } from 'semantic-ui-react'
import HomePage from './pages/HomePage';
import EventPage from './pages/EventPage';
import DirectoryPage from './pages/DirectoryPage';
import QueryPage from './pages/QueryPage';
import LoginPage from './pages/LoginPage';
import React from 'react';
import firebase from './utils/firebase';
import TemplatesPage from './pages/TemplatesPage';
import ProfilePage from './pages/ProfilePage';
import BiblePage from './pages/BiblePage';
import config from './utils/config';
import NavBar from './components/NavBar';
import colors from './utils/colors';
import { isMobile } from 'react-device-detect';
import Page from './components/Page';
import Footer from './components/Footer';
import LinkPage from './pages/LinkPage';
import UnAuthPage from './pages/UnAuthPage';
import PrivatePage from './pages/PrivatePage';
import PublicPage from './pages/PublicPage';
import GroupsDirectoryPage from './pages/GroupsDirectoryPage';
import EventPortal from './pages/EventPortal';
import authCache from './caches/authCache';
import AlertsPage from './pages/AlertsPage';
import AlertCommentsPage from './pages/AlertCommentsPage';
import GroupPage from './pages/GroupPage';
import ReactGA from "react-ga4";
import UpcomingPage from './pages/UpcomingPage';
import EventForm from './pages/EventForm';
import { LoaderPopup } from './popups/LoaderPopup';
import EventTemplateForm from './pages/EventTemplateForm';
import DashboardPage from './pages/DashboardPage';

ReactGA.initialize(config.analyticsMeasurementId);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkedAccount: false,
      authLoaded: false,
      loggedIn: false,
      user: null,
      auth: null,
      enabled: null,
      userEmails: [],
      sidebarVisible: false,
      pagePermissions: {},
      windowWidth: window.innerWidth,
      pathname: window.location.pathname,
      pageTabs: [],
    }
  }

  componentDidMount = async () => {
    const start = new Date();
    firebase.auth().onAuthStateChanged(async (user) => {
      console.debug('1. Loaded Firebase auth in', new Date() - start, 'ms');

      const start2 = new Date();
      await this.handleCurrentUser(user);
      console.debug(`2. Loaded handleCurrentUser in`, new Date() - start2, 'ms');

      console.debug(`3. Loaded index.js in`, new Date() - start, 'ms');
    });

    window.addEventListener('resize', () => {
      this.setState({ windowWidth: window.innerWidth });
    });
  }

  handleCurrentUser = (user) => {
    return new Promise(async (resolve) => {
      var auth;
      if (user) {
        const start3 = new Date();
        auth = await authCache.Initialize(user?.uid);
        console.debug(`Loaded authCache in`, new Date() - start3, 'ms');

        ReactGA.set({
          userId: `${auth?.fullname} (${auth?.id})`,
        });
      }
      else {
        // signed out or never logged in..
        auth = null;
        authCache.SetAuth(null);
      }

      ReactGA.event({ category: 'auth', action: user ? 'signed_in' : 'signed_out' });

      if (user && !auth) {
        console.log('user signed in but missing linked account');
      };

      this.setState({
        loggedIn: user !== null,
        authLoaded: true,
        user,
        auth,
        // account can only be disabled if it exists..
        disabled: auth ? auth.enabled !== true : false,
        linkedAccount: (user && auth),
      }, async () => {
        await this.updatePermissions(auth);
        resolve();
      });
    })
  }

  updatePermissions = (auth) => {
    return new Promise(async (resolve) => {
      const pagePermissions =
      {
        dashboard: auth?.admin,
        permissions: auth?.admin,
        people: auth?.staff || auth?.admin,
        search: auth?.staff || auth?.admin,
        editor: auth?.staff || auth?.admin,
        template: auth?.staff || auth?.admin,
        templates: auth?.staff || auth?.admin,
        groups: auth?.staff || auth?.admin,
        alerts: auth?.staff || auth?.admin,
      };

      var pageTabs = [];

      pageTabs.push({ route: 'calendar', icon: 'calendar', label: 'Calendar', mobile: true });

      pageTabs.push({ route: 'upcoming', icon: 'upcoming', label: 'Upcoming' });

      if (pagePermissions.people)
        pageTabs.push({ route: 'people', icon: 'users', label: 'People', mobile: false });

      pageTabs.push({ route: 'groups', icon: 'group', label: 'Groups' });

      if (pagePermissions.templates)
        pageTabs.push({ route: 'templates', icon: 'list alternate outline', label: 'Templates' });

      if (pagePermissions.search)
        pageTabs.push({ route: 'search', icon: 'search', label: 'Search', mobile: false });

      if (pagePermissions.alerts)
        pageTabs.push({ route: 'alerts', icon: 'alerts', label: 'Alerts' });

      if (pagePermissions.dashboard)
        pageTabs.push({ route: 'dashboard', icon: 'dashboard', label: 'Dashboard' });

      pageTabs.push({ route: 'profile', icon: 'user circle', label: 'Profile', mobile: true });

      this.setState({
        pagePermissions,
        pageTabs
      }, () => resolve());
    });
  }

  render() {
    const {
      sidebarVisible,
      authLoaded,
      auth,
      pagePermissions,
      windowWidth,
      pageTabs
    } = this.state;

    return (
      <BrowserRouter>

        <Sidebar.Pushable className="phsidebar" as={Segment}>

          <Sidebar
            as={Menu}
            animation='overlay'
            icon={isMobile ? 'labeled' : false}
            onHide={() => this.setState({ sidebarVisible: false })}
            vertical
            visible={sidebarVisible}
            width={isMobile ? 'thin' : 'wide'}
            direction={'top'}
          >
            {pageTabs?.map((tab, idx) => {
              return (
                <Menu.Item key={idx} as={Link} to={tab.route} onClick={() => this.setState({ sidebarVisible: false })}>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    {tab.label}
                  </div>
                </Menu.Item>
              )
            })}
          </Sidebar>

          <Sidebar.Pusher>
            <div style={{ backgroundColor: colors.pageBackground }}>

              <NavBar
                authLoaded={authLoaded}
                pageTabs={pageTabs}
                pagePermissions={pagePermissions}
                isAdmin={auth?.admin ? true : false}
                setSideBar={() => this.setState({ sidebarVisible: !this.state.sidebarVisible })}
                windowWidth={windowWidth}
              />

              <Routes>

                {(config.siteName === 'LifeWay' || config.siteName === 'Local') &&
                  <Route path='relight'
                    element={
                      <PublicRoute
                        {...this.state}
                        page={<EventPortal
                          {...this.state}
                          returnPage='https://lbc.scheduly.org/relight'
                          btnLinks={[
                            {
                              label: 'DAY 1',
                              internalUrl: '/event?id=WWaYLF2BNPro20HmOlrD&con=true&guest=true&return=relight',
                            },
                            {
                              label: 'DAY 2',
                              internalUrl: '/event?id=UeVVagHK2JHjJMfiRQ4s&con=true&guest=true&return=relight',
                            },
                            // {
                            //   label: 'OUTREACH',
                            //   externalUrl: 'https://sites.google.com/view/outreach2023/home',
                            //   color: 'orange'
                            // }, 
                          ]}
                        />}
                      />}
                  />
                }

                {(config.siteName === 'LifeWay' || config.siteName === 'Local') &&
                  <Route path='relight_staff'
                    element={
                      <PrivateRoute
                        {...this.state}
                        page={
                          <EventPortal
                            {...this.state}
                            returnPage='https://lbc.scheduly.org/relight_staff'
                            btnLinks={[
                              {
                                label: 'DAY 1 - EVENING',
                                internalUrl: '/event?id=EM5W5vlt0WOuhVCXJZd7&con=true&guest=false&return=relight_staff',
                              },
                              {
                                label: 'DAY 2 - MORNING',
                                internalUrl: '/event?id=Nc0dUdAbefIvdxqrZO0W&con=true&guest=false&return=relight_staff',
                              },
                              {
                                label: 'DAY 2 - AFTERNOON',
                                internalUrl: '/event?id=1r2T6akdYGCSAmztScnX&con=true&guest=false&return=relight_staff',
                              },
                              // {
                              //   label: 'OUTREACH',
                              //   externalUrl: 'https://sites.google.com/view/outreach2023/home',
                              //   color: 'orange'
                              // },
                            ]}
                          />
                        }
                      />}
                  />
                }

                {/* Private Pages */}
                <Route index element={
                  <PrivateRoute
                    {...this.state}
                    page={<Navigate to='/calendar' />}
                  />}
                />

                <Route path='upcoming' element={
                  <PublicRoute
                    {...this.state}
                    page={<UpcomingPage {...this.state} />}
                  />}
                />

                <Route path='calendar' element={
                  <PrivateRoute
                    {...this.state}
                    page={<HomePage {...this.state} />}
                  />}
                />

                <Route path='editor' element={
                  <PrivateRoute
                    {...this.state}
                    hasPermission={pagePermissions.editor}
                    page={<EventForm {...this.state} />}
                  />}
                />

                <Route path='people' element={
                  <PrivateRoute
                    {...this.state}
                    hasPermission={pagePermissions.people}
                    page={<DirectoryPage {...this.state} />}
                  />}
                />

                <Route path='templates' element={
                  <PrivateRoute
                    {...this.state}
                    hasPermission={pagePermissions.templates}
                    page={<TemplatesPage {...this.state} />}
                  />}
                />

                <Route path='template' element={
                  <PrivateRoute
                    {...this.state}
                    hasPermission={pagePermissions.template}
                    page={<EventTemplateForm {...this.state} />}
                  />}
                />

                <Route path='search' element={
                  <PrivateRoute
                    {...this.state}
                    hasPermission={pagePermissions.search}
                    page={<QueryPage {...this.state} />}
                  />}
                />

                <Route path='profile' element={
                  <PrivateRoute
                    {...this.state}
                    page={<ProfilePage {...this.state} />}
                  />}
                />

                <Route path='bible' element={
                  <PrivateRoute
                    {...this.state}
                    page={<BiblePage {...this.state} />}
                  />}
                />

                <Route path='alerts' element={
                  <PrivateRoute
                    {...this.state}
                    hasPermission={pagePermissions.posts}
                    page={<AlertsPage {...this.state} />}
                  />}
                />

                <Route path='alert' element={
                  <PrivateRoute
                    {...this.state}
                    page={<AlertCommentsPage {...this.state} />}
                  />}
                />

                <Route path='dashboard' element={
                  <PrivateRoute
                    {...this.state}
                    hasPermission={pagePermissions.dashboard}
                    page={<DashboardPage {...this.state} />}
                  />}
                />

                <Route path='private' element={
                  <PrivateRoute
                    {...this.state}
                    page={<PrivatePage {...this.state} />}
                  />}
                />


                {/*  Public pages */}

                <Route path='groups' element={
                  <PublicRoute
                    {...this.state}
                    page={<GroupsDirectoryPage {...this.state} />}
                  />}
                />

                <Route path='group' element={
                  <PublicRoute
                    {...this.state}
                    page={<GroupPage {...this.state} />}
                  />}
                />

                <Route path='event' element={
                  <PublicRoute
                    {...this.state}
                    page={<EventPage {...this.state} />}
                  />}
                />

                {/* Shortcut for SHORT URLS */}
                <Route path='e' element={
                  <PublicRoute
                    {...this.state}
                    page={<EventPage {...this.state} />}
                  />}
                />

                <Route path='*' element={
                  <PublicRoute
                    {...this.state}
                    page={<Navigate to='/calendar' />}
                  />}
                />

                {/* Do not wrap these pages.. */}
                <Route path='login' element={<LoginPage {...this.state} />} />
                <Route path='link' element={<LinkPage {...this.state} />} />

                <Route path='public' element={<PublicPage {...this.state} />} />

              </Routes>

              <Footer />

            </div>

            <LoaderPopup open={!this.state.authLoaded} showLogo title={`Loading Profile ..`} />

          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </BrowserRouter>
    );
  }
}

const PrivateRoute = (props) => {
  const { user, authLoaded, loggedIn, disabled, page, windowWidth } = props;
  var { hasPermission } = props;

  // if no permission is required, default to allowed..
  if (hasPermission === undefined) hasPermission = true;

  const prevPathname = window.location.pathname;
  const prevSearch = window.location.search;

  const needsToLink = !authCache.GetAuth() && user;
  if (needsToLink) console.log('redirecting to link page, returning to', prevPathname, prevSearch);

  return (
    authLoaded && hasPermission
      ? loggedIn
        ? disabled
          ? <UnAuthPage windowWidth={windowWidth} />
          : !needsToLink
            ? page
            : <Navigate to='/link' state={{ prevPathname, prevSearch }} />
        : <Navigate to='/login' state={{ prevPathname, prevSearch }} />
      : <Page />);
};

const PublicRoute = (props) => {
  const { user, authLoaded, page } = props;

  const prevPathname = window.location.pathname;
  const prevSearch = window.location.search;

  const needsToLink = !authCache.GetAuth() && user;
  if (needsToLink) console.log('redirecting to link page, returning to', prevPathname, prevSearch);

  return (
    authLoaded
      ? !needsToLink
        ? page
        : <Navigate to='/link' state={{ prevPathname, prevSearch }} />
      : <Page />);
};

ReactDOM.render(<App />, document.getElementById('root'));

import React, { useState } from 'react';
import { Calendar, momentLocalizer, components } from 'react-big-calendar';
import '../App.css';
import moment from 'moment';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import withRouter from '../utils/withRouter';
import { parseJSON } from 'date-fns';
import CalendarToolBar from '../components/CalendarToolBar';
import api from '../utils/api';
import { Checkbox, Segment, Grid, Button } from 'semantic-ui-react';
import colors from '../utils/colors';
import { isSameMonth, isSameDay, setDate, getDate, differenceInYears, setYear, isAfter, format, differenceInWeeks, addDays, getYear, getMonth, isValid, endOfDay, startOfDay, startOfWeek, endOfWeek, endOfMonth, startOfMonth} from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import utils from '../utils/utils';
import Page from '../components/Page';
import {isDesktop, isMobile} from 'react-device-detect';
import ScheduleView from '../components/ScheduleView';
import '../styles.scss';
import EmptySegment from '../components/EmptySegment';
import CalendarEventPreview from '../components/CalendarEventPreview';
import memberCache from '../caches/memberCache';
import DeskstopCalendarToolBar from '../components/DeskstopCalendarToolBar';
import EventPopup from '../popups/EventPopup';
import ReactGA from 'react-ga4';

const localizer = momentLocalizer(moment);

const STANDARD_CALENDAR_HEIGHT = 450;

class HomePage extends React.Component {

  constructor(props) 
  {
    super(props);
    this.state = {
      selected: null,
      loading: true,
      monthLoading: false,
      error: null,
      canAddEvent: false,
      monthlyEvents: [],
      previewEvents: [],
      selectedDate: null,
      calendarHeight: STANDARD_CALENDAR_HEIGHT,
      selectedView: 'month',
      currentMonthIdx: null,
      eventId: null,
      enableBirthdays: false,
      enableEvents: true,
      adminView: false,
      selectedEventId: null,
      view: isMobile ? 'mobile' : 'desktop',
      calendarView: 'month'
    }

    this.scheduleView = React.createRef(); 
  }

  componentDidMount = async () =>
  {
    ReactGA.send('pageview');
    this.reloadPage();
  }
  
  reloadPage = async () => 
  {
    this.restoreParams();
  }

  restoreParams = async () =>
  {
    const params = queryString.parse(this.props.router.location.search);    
    const selectedDate = params.date 
      ? isValid(new Date(params.date)) 
        ? new Date(params.date) 
        : new Date()
      : new Date();

    await this.onChangeDate(selectedDate);

    this.setState({
      loading: false, 
      canAddEvent: this.props.auth?.isStaff
    });
  }

  getMonthlyEvents = (selectedDate) => 
  {
    return new Promise(async (resolve) => 
    {
      var month = isValid(selectedDate) ? format(selectedDate, 'MM') : null;
      var year = isValid(selectedDate) ? format(selectedDate, 'yyyy') : null;

      utils.updateSearchParams(this.props, [{key: 'date', value: selectedDate}]);
  
      this.setState({monthLoading: true}, () => 
      {
        api.getMonthlyEvents(month, year)
          .then((res) => 
          {
            const monthlyEvents = [];
            res?.events?.forEach((e) => 
            {
              if (isValid(e.start) && isValid(e.end) && isAfter(e.end, e.start))
              {
                var currDate = new Date(e.start.toDateString());
                if (this.state.view === 'mobile')
                {
                  while (isAfter(e.end, currDate))
                  {
                    const copy = utils.deepClone(e);
                    copy.start =  parseJSON(copy.start);
                    copy.end = parseJSON(copy.end);
  
                    // ends on diff date
                    const sameStart =  e.start.toDateString() === currDate.toDateString();
                    const sameEnd =  e.end.toDateString() === currDate.toDateString();
  
                    copy.start_date = sameStart ? e.start : startOfDay(currDate); 
                    copy.end_date = sameEnd ? e.end : endOfDay(currDate); 
                    copy.allDay = !sameStart && !sameEnd;
                    copy.sameStart = sameStart;
                    copy.sameEnd = sameEnd;
                   
                    monthlyEvents.push(copy);
      
                    currDate = addDays(currDate, 1);
                  }
                }
                else 
                {
                  // desktop events should span across days..
                  const copy = utils.deepClone(e);
                  copy.start =  parseJSON(copy.start);
                  copy.end = parseJSON(copy.end);

                  const sameStart =  e.start.toDateString() === currDate.toDateString();
                  const sameEnd =  e.end.toDateString() === currDate.toDateString();
                  
                  copy.start_date = e.start;
                  copy.end_date =  e.end;
                  copy.allDay = !sameStart && !sameEnd;
                  copy.sameStart = sameStart;
                  copy.sameEnd = sameEnd;

                  monthlyEvents.push(copy);
                }
              }
            })
            this.setState({monthlyEvents, monthLoading: false}, () => resolve());
          })
          .catch((error) => 
          {
            this.setState({error: error.includes('Not Found') ? null : error, loading:false, monthLoading: false}, () => resolve());
          });
      });
    })
  }

  updateBirthdayEvents = async (currDate) =>
  {
    const members = await memberCache.GetMembersDict();

    var monthlyEvents = [...this.state.monthlyEvents];
    if (!this.state.enableBirthdays)
    {
      // hide all birthdays from calendar..
      monthlyEvents = monthlyEvents.filter(e => !e.birthday);
      this.setState({ monthlyEvents });
      return;
    }

    const currMonth = getMonth(currDate);
    const processedBdays = {};
    monthlyEvents.forEach(e => 
    {
      if (e.birthday) processedBdays[e.member?.id] = true;
    });

    Object.values(members)?.forEach(member => 
      {
        if (processedBdays[member.id]) return;
        if (!member.dob) return;

        const dob = parseJSON(member.dob);
        const calDate = setYear(dob, getYear(currDate));

        if (getMonth(dob) === currMonth)
        {
          // get birth date of current month to compute age..
          const monthDate = startOfDay(setDate(currDate, getDate(dob)));
          const age = differenceInYears(monthDate, startOfDay(dob));

          monthlyEvents.push({
            name: this.state.view === 'mobile' ? `${member.fullname}'s Birthday` : member.fullname,
            start_date: calDate,
            end_date: endOfDay(calDate),
            location: `Turning ${age} years old`,
            allDay: true,
            member: member,
            birthday: true,
            color: 'green'
          });
        }
      });

    this.setState({monthlyEvents});
  }

  updatePreviewEvents = (date) => 
  {
    const { monthlyEvents } = this.state;

    var previewEvents = monthlyEvents.filter(e => e.start_date.toDateString() === date?.toDateString());
    // sort events for the day by starting time..
    previewEvents = previewEvents.sort(function(a, b){
      if(a.start_date < b.start_date) { return -1; }
      if(a.start_date > b.start_date) { return 1; }
      return 0;
    });

    this.setState({
      previewEvents, 
      eventId: previewEvents?.length > 0 ? previewEvents[0].id : null
    });
  }
  
  onChangeDate = (newDate, forceLoad = false) => 
  {
    return new Promise((resolve) => 
    {
      const prevDate = new Date(this.state.selectedDate);
      this.setState({eventId: null, selectedDate: newDate}, async () => 
      {
        await this.adjustCalendarMonth(newDate);

        api.logRemote(`selected calendar date ${isValid(newDate) ? format(newDate, 'MM/dd/yyyy') : newDate}`)
        utils.updateSearchParams(this.props, [{key: 'date', value: newDate}]);
  
        var dateChanged = getMonth(prevDate) !== getMonth(newDate) || getYear(prevDate) !== getYear(newDate);
        if (forceLoad || (this.state.enableEvents && dateChanged))
        {
          if (this.state.enableEvents) {
            await this.getMonthlyEvents(newDate);
          }
          else {
            this.setState({ monthlyEvents: [] })
          }
        }
      
        if (this.props.auth?.isStaff) await this.updateBirthdayEvents(newDate);
        this.updatePreviewEvents(newDate);

        resolve();
      });
    })
  }

  adjustCalendarMonth = (date) => 
  {
    return new Promise((resolve) => 
    {
      const start = startOfWeek(startOfMonth(date));
      const end = endOfWeek(endOfMonth(date));
      
      const weeks = differenceInWeeks(end, start);
      const currentMonthIdx = getMonth(date)
  
      this.setState({
        calendarHeight: weeks === 4? STANDARD_CALENDAR_HEIGHT : 525,
        currentMonthIdx,
      }, () => resolve());
    })
  }

  render() 
  {
    const { 
      monthlyEvents, 
      selected, 
      error,
      selectedDate,
      calendarHeight,
      currentMonthIdx,
      previewEvents,
      canAddEvent,
      monthLoading,
      adminView,
      enableEvents,
      enableBirthdays,
      selectedEventId,
      view,
      calendarView
    } = this.state;

    return (
      <Page 
        header='Calendar'
        helmet={`Calendar - Scheduly`} 
        error={error}
        windowWidth={this.props.windowWidth}
        padded={false}
        btnText={canAddEvent ? 'Add Event' : null}
        btnAction={() =>  canAddEvent ? this.props.router.navigate('/editor') : null}
      > 
        {isDesktop &&
          <div style={{display: 'flex', width: '100%', justifyContent: 'end', margin: 20, paddingRight: 25}} >
            <Button.Group>
              <Button compact color={view === 'desktop' ? 'violet' : null} onClick={() => this.setState({view: 'desktop'}, () => this.onChangeDate(this.state.selectedDate, true))} icon='user'>
                Full Calendar
              </Button>
              <Button.Or text='or' />
              <Button compact color={view === 'mobile' ? 'purple' : null}  onClick={() => this.setState({view: 'mobile'}, () => this.onChangeDate(this.state.selectedDate, true))}>
                Side by Side
              </Button>
            </Button.Group>
          </div>
        }

        {view === 'desktop' &&
          <Segment style={{marginTop: 30, height: 800, borderWidth: 1, borderRadius: 10}}>
            <Calendar 
              messages={{showMore: (count) => <div style={{padding: 5}}> + {count} more</div>}}
              localizer={localizer}
              events={monthlyEvents}
              startAccessor='start_date'
              endAccessor='end_date'
              titleAccessor='name'
              selected={selected}
              date={selectedDate}
              onSelectEvent={(event, e) => {
                if (event.birthday)
                {
                  this.props.router.navigate(`/profile?id=${event.member.id}`)
                  return;
                }
                this.setState({selectedEventId: event.id})}
              }
              onNavigate={(newDate, view, action) => {
                // if (view === 'month') {
                //   // prevents weird flicker of events in monthly calendar view..
                //   this.setState({monthlyEvents: []}, () => this.onChangeDate(newDate))
                //   return;
                // }

                this.onChangeDate(newDate);
              }}
              onView={(view) => this.setState({calendarView: view})}
              onRangeChange={async ({start, end}) => {
                start = endOfWeek(start);
                await this.adjustCalendarMonth(start);
              }}
              eventPropGetter={
                (event, start, end, isSelected) => {
                  const newStyle = {
                    backgroundColor: event.color,
                    color: colors.white,
                    borderRadius: 5,
                    paddingLeft: 15
                  };
                  return {
                    style: newStyle
                  };
                }
              }
              dayPropGetter={(date) => 
              {
                var sameMonth = isSameMonth(date, selectedDate);
                var isStartOfWeek = startOfWeek(date).getDate() === date.getDate();
                var today = isSameDay(date, new Date());

                return {
                  style: {
                    backgroundColor: !sameMonth || calendarView !== 'month'? colors.pageBackground : today ? '#e2f4f9' : null,
                    borderColor: colors.lightGray,
                    borderLeftWidth: isStartOfWeek ? 0 : 2,
                    borderLeftStyle: 'solid',
                    borderTopWidth: 2,
                    borderTopStyle: 'solid',
                  },
                };
              }}
              onDoubleClickEvent={event => this.props.router.navigate('/editor', {	state: { event }})}
              components={{
                toolbar: (props) => {
                  const selectToday = () => this.onChangeDate(new Date());
                  return DeskstopCalendarToolBar(props, this.props.windowWidth, selectToday, monthLoading);
                }
              }}
            />
             {this.props.auth?.isStaff &&
              <div style={{marginTop: 30, marginBottom: 20, height: 80}}>
                <Checkbox  defaultChecked={enableEvents} label='Events' style={{marginRight: 15}}
                  onClick={(e, d) => {
                    ReactGA.event({ category: 'HomePage', action: `Toggle Events` });
                    this.setState({enableEvents: d.checked}, () => this.onChangeDate(this.state.selectedDate, true))
                  }}
                />
                <Checkbox defaultChecked={enableBirthdays} label='Birthdays' style={{marginRight: 15}}
                  onClick={(e, d) => {
                    ReactGA.event({ category: 'HomePage', action: `Toggle Birthdays` });
                    this.setState({enableBirthdays: d.checked}, () => this.onChangeDate(this.state.selectedDate, true))
                  }}
                />
              </div>
            }
          </Segment>
        }
        {view === 'mobile' &&
          <Grid stackable relaxed style={{paddingTop: 20}}>
            <Grid.Column width={6} style={{paddingRight: 20, paddingLeft: 40}}>
              <div style={{backgroundColor: '#f9fafb', borderRadius: 5, borderColor: colors.border, borderWidth: 1, borderStyle: 'solid', padding: 0}}>
              <Calendar 
                localizer={localizer}
                events={monthlyEvents}
                startAccessor='start_date'
                endAccessor='end_date'
                titleAccessor='name'
                selected={selected}
                date={selectedDate}
                onSelectEvent={(event, e) => this.onChangeDate(event.start_date)}
                onNavigate={(newDate, view, action) => this.onChangeDate(newDate)}
                style={{ height: calendarHeight, backgroundColor: colors.white, paddingTop: 30, paddingBottom: 5, borderRadius: 5, borderWidth: 0, borderColor: colors.midGray}}
                popup={false}
                onRangeChange={async ({start, end}) => {
                  start = endOfWeek(start);
                  await this.adjustCalendarMonth(start);
                }}
                onDoubleClickEvent={event => this.props.router.navigate('/editor', {	state: { event }})}
                components={{
                  toolbar: (props) => {
                    const selectToday = () => this.onChangeDate(new Date());
                    return CalendarToolBar(props, this.props.windowWidth, selectToday, monthLoading);
                  },
                  eventWrapper: (props) => this.state.selectedView === 'month' 
                    ? CalenderEventWrapper(props) 
                    : components.eventWrapper(props),
                  month: {
                    header: (props) => {
                      var dayOfWeekShort = props?.label[0];
                      return (<div style={{color: monthLoading ? colors.midGray : colors.darkGray}}>
                        {isMobile? dayOfWeekShort : props.label?.toUpperCase()}
                        </div>);
                    },
                    dateHeader: (props) =>   
                    {
                      return (
                        <CellDateHeader
                          date={props.date}
                          loading={monthLoading} 
                          selectedDate={selectedDate} 
                          currentMonthIdx={currentMonthIdx} 
                          onClick={async (date) => 
                          {
                            await this.onChangeDate(props.date);
                          }}
                        />)
                    },
                    event: (props) => {
                      var isCurrentMonth = currentMonthIdx === getMonth(props.event?.start_date);
                      return (
                        <div 
                          style={{
                            backgroundColor: isCurrentMonth ? props.event?.birthday ? 'green' : colors.accent : colors.midGray,
                            height: 10, 
                            width: 10, 
                            borderRadius: 5,
                          }}
                        ></div>
                      );
                    }
                  },
                }}
              />
            </div>

            {this.props.auth?.isStaff &&
              <div style={{marginTop: 20}}>
                <Checkbox defaultChecked={enableEvents} label='Events' style={{marginRight: 15}}
                  onClick={(e, d) => this.setState({enableEvents: d.checked}, () => this.onChangeDate(this.state.selectedDate, true))}
                />
                <Checkbox defaultChecked={enableBirthdays} label='Birthdays' style={{marginRight: 15}}
                  onClick={(e, d) => this.setState({enableBirthdays: d.checked}, () => this.onChangeDate(this.state.selectedDate, true))}
                />
              </div>
            }

            <div style={{marginTop: 30, minHeight: 120, marginBottom: 30}}>
              {previewEvents?.length > 0 &&
                <div style={{fontSize: 16, color: colors.darkGray, paddingBottom: 10}}>
                  {selectedDate ? format(selectedDate, 'eeee MM/dd/yyyy') : ''}
                </div>
              }

              {previewEvents?.map((event, idx) => {
                return (
                  <CalendarEventPreview
                    key={idx}
                    selectedDate={this.state.selectedDate}
                    event={event} 
                    adminView={adminView}
                    showDivider={idx !== previewEvents.length - 1}
                    onClick={() => 
                    {
                      if (event.birthday)
                      {
                        this.props.router.navigate(`/profile?id=${event.member.id}`)
                      }

                      this.setState({eventId: event?.id}, () => 
                      {
                        if (isMobile)
                          this.scheduleView?.current.scrollIntoView({behavior: 'smooth'});
                      }); 
                    }}
                  />
                )
              })} 
            </div>
          </Grid.Column>

          {(this.state.eventId || !isMobile) && 
            <Grid.Column width={10} style={{backgroundColor: this.state.eventId ? colors.white : colors.pageBackground, borderRadius: 5, borderColor: colors.border, borderStyle: 'solid', borderWidth: 1, marginTop: 13}}>
              <div style={{padding: isMobile? 8 : 20, height: '100%', minHeight: 300}} ref={this.scheduleView}>

                {!this.state.eventId && !isMobile && 
                  <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', color: colors.darkGray}}>
                    <EmptySegment 
                      title="Event not Selected"
                      description="Select an event from the calendar"
                      icon='calendar times outline'
                      iconSize='big'
                      iconColor={colors.primary}
                    />
                  </div>
                }
                {this.state.eventId &&
                  <ScheduleView 
                    router={this.props.router}
                    eventId={this.state.eventId}
                    memberId={this.props.auth?.id}
                    confirmed={true}
                    user={this.props.user}
                    userEmails={this.props.userEmails}
                    showAllParticipants
                  /> 
                }
            </div>
          </Grid.Column>
        }
        </Grid>
      }

      <EventPopup
        open={selectedEventId !== null}
        eventId={selectedEventId} 
        user={this.props.user}
        onClose={()=> this.setState({selectedEventId: null})}
      />
    </Page>
    )
  }
}

function CalenderEventWrapper(props)
{
  const { event, onSelect, onDoubleClick } = props;

  return (
    <div 
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        padding: 3,
      }}
      onClick={() => onSelect(event)}
    >
      {props.components.event(props, onDoubleClick)}
    </div>
  )
}

function CellDateHeader(props)
{
  const { date, selectedDate, currentMonthIdx, onClick, loading} = props;

  var cellDate = date.getDate();
  var today = new Date();
  var isToday = today.toDateString() === selectedDate?.toDateString();
  var isSelected = selectedDate?.toDateString() === date.toDateString();
  var isCurrentMonth = currentMonthIdx === getMonth(date);

  var [hovered, setHovered] = useState(false);

  return(
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div 
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          margin: 5,
          fontSize: '1.2em', 
          textAlign: 'center', 
          cursor: 'pointer', 
          backgroundColor: hovered || isSelected ? (isToday? colors.accent : colors.primary) : colors.white,
          color: loading ? colors.midGray : isCurrentMonth? (isSelected || hovered? isToday? colors.white : colors.white : colors.black) : colors.midGray,
          borderRadius: 20,
          height: 40,
          width: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onClick}
      >
        {cellDate}
      </div>
    </div>)
}

function HomePageHooks(props)
{
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  return (<HomePage setSearchParams={setSearchParams} {...props}/>)
}

export default withRouter(HomePageHooks);